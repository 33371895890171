import React from "react";
import {
  SEO,
  ContactForm,
  createDefaultMessageFromState,
  MarkdownContent,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class EmploymentPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1256}
                  businessType={"merchant"}
                  locationId={1323}
                  subheader={"Hiring"}
                  showLabels={true}
                  emailSubject={"Hiring"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "Full Name",
                      displayLabel: "Name",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "email",
                      placeholder: "example@gmail.com",
                      displayLabel: "Email",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "date",
                      required: true,
                      inputType: "text",
                      placeholder: "mm/dd/yyyy",
                      displayLabel: "Date",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "address",
                      required: true,
                      inputType: "text",
                      placeholder: "Address",
                      displayLabel: "Address",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "telephone_cellphone",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Telephone/Cellphone",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "social_security",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Social Security #",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "select",
                      label:
                        "are_you_a_u_s_citizen_or_otherwise_authorized_to_work_in_the_u_s_on_an_unrestricted_basis_you_may_be_required_to_provide_documentation",
                      options: [
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ],
                      required: true,
                      inputType: "select",
                      placeholder: "",
                      displayLabel:
                        "Are you a U.S citizen or otherwise authorized to work in the U.S on an unrestricted basis? (You may be required to provide documentation.)",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "position_applied_for",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Position Applied For",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "desired_starting_wage",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Desired Starting Wage",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "how_did_you_hear_this_opening",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "How did you Hear this opening?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "date_you_can_start",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Date you can start",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "reliable_transportation",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Reliable Transportation",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "desired_days_hours_to_work",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Desired Days/Hours to work?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "are_you_able_to_attend_scheduled_shifts_on_time",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to attend scheduled shifts on time?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_able_to_manage_cleaning_dog_accidents_cages_suites_indoor_and_outdoor",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to manage cleaning dog accidents, cages, suites, (indoor and outdoor)?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_able_to_greet_collect_and_follow_groom_instructions",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to greet, collect, and follow groom instructions?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_able_to_clean_and_sanitize_your_station_after_use_or_shift",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to clean and sanitize your station after use/or shift?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_able_to_see_your_services_from_start_to_finish_walk_bathe_dry_cut_check_out_clean",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to see your services from start to finish (walk, bathe, dry, cut, check-out, clean)?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_able_to_assist_in_bathing_drying_and_nail_trims_if_necessary",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you able to assist in bathing/drying and nail trims if necessary?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "please_list_applicable_skills_or_experience",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Please list applicable skills or experience",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "high_school",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "High School",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "college",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "College",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "other",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Other",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label:
                        "list_three_personal_references_not_related_to_you_who_have_known_you_for_more_than_one_year",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Name, Phone, Years Known",
                      displayLabel:
                        "List three personal references, not related to you, who have known you for more than one year.",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "emergency_contact",
                      required: true,
                      inputType: "text",
                      placeholder: "Name and Phone number",
                      displayLabel: "Emergency Contact",
                      isFieldContactMapped: false,
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "5e41701e-1864-4c79-9c4a-96cc39c173c5",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22319 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
